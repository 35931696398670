import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import Table from '+shared/Table';
import { IEvent } from '+types';
import { filteredOutObjectProperty, formatAllTime, getDate, getTime, logError, queriesParams } from '+utils';
import useStore from '+zustandStore';

import LargeExportModal from '../Shared/LargeExportModal';

import './index.scss';

const api = new APIRequest();

const extractNameAndEmail = (input: string): string => {
  const index = input.indexOf(')');
  return index !== -1 ? input.substring(0, index + 1).trim() : input;
};

const AuditLogs = () => {
  const { feedbackInit } = useFeedbackHandler();
  const [showLargeExportModal, setLargeExportModal] = useState(false);
  const { profile } = useStore(state => state);

  const searchQuery = useSearchQuery();
  const page = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '25';
  const dateFrom = searchQuery.value?.dateFrom || '';
  const dateTo = searchQuery.value?.dateTo || '';
  const status = searchQuery.value?.status || '';
  const email = searchQuery.value?.email || '';

  const sortingParams = {
    ...(dateFrom && { date_from: formatAllTime(dateFrom) }),
    ...(dateTo && { date_to: formatAllTime(dateTo) }),
    ...(status && { event: status }),
    ...(email && { email: email }),
    ...filteredOutObjectProperty(searchQuery.value, [
      queriesParams.tab,
      queriesParams.limit,
      queriesParams.sorterType,
      queriesParams.dateFrom,
      queriesParams.dateTo,
      queriesParams.status,
      queriesParams.page,
      queriesParams.totalItems
    ])
  };

  const {
    data: audits,
    isFetching,
    refetch
  } = useQuery(['AUDIT_LOGS', page, limit, status, dateFrom, dateTo, email], () => api.getLogs(page, limit, sortingParams), {
    keepPreviousData: true,
    onError: error => {
      if (error) {
        logError(error);
        feedbackInit({
          message: `There has been an error getting this admin activity log`,
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      }
    }
  });

  const paging = audits?.paging;

  const paginate = (currentPage: string | number) => {
    searchQuery.setQuery({ page: String(currentPage) });
  };
  const renderEvents = (event: IEvent) => {
    return {
      data: {
        action: event?.event,
        user: extractNameAndEmail(event?.admin?.email || 'N/A'),
        ip_address: event?.metadata
          ? event?.metadata?.location?.city + ', ' + event?.metadata?.location?.country + ', ' + event?.metadata?.location?.ipAddress
          : 'N/A',
        event_id: <span>{event?.event_id}</span>,
        created_at: (
          <div style={{ margin: 0, padding: 0 }}>
            {getDate(event?.created_at)}&nbsp;<span style={{ color: '#A9AFBC' }}>{getTime(event.created_at)}</span>
          </div>
        )
      }
    };
  };

  return (
    <>
      <LargeExportModal close={() => setLargeExportModal(false)} email={profile.email as string} visible={showLargeExportModal} />
      <div className="content-i">
        <div className="content-box">
          <div className="os-tabs-w mb-3">
            <div className="tab-content">
              <div className="tab-pane active" id="tab_activity">
                <Table
                  className="--event-log-table"
                  hasPagination
                  header={false}
                  tableHeadings={['Action', 'User', 'IP Address', 'Event ID', 'Date/Time']}
                  totalItems={paging?.total_items || 0}
                  pageSize={paging?.page_size || 0}
                  loading={isFetching}
                  current={parseInt(page, 10)}
                  actionFn={paginate}
                  annotation="logs"
                  emptyStateHeading="There are no audit logs yet"
                  emptyStateMessage="You do not have any audit logs at the moment."
                  tableWrapperClassName="table-responsive"
                  renderFields
                  data={audits?.data || []}
                  filterType="audit-logs"
                  openSummary={true}
                  filterHasAdvancedFilter={false}
                >
                  {renderEvents}
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuditLogs;
